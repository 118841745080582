import React from 'react';
import { ElfsightWidget } from 'react-elfsight-widget';

function Component() {
  return <
  
  ElfsightWidget widgetID="d9ba9db0-2a4c-40ca-868f-6f20917c2a38"
  
   />;
  
}
  
  export default Component;
  