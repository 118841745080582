import React, {useState} from 'react'
import {db} from "../.././firebase"
import { addDoc,collection } from 'firebase/firestore'
import { Link, useNavigate } from "react-router-dom";
import PopupGfg from './import';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
function Foo() {
  
 

  const navigate = useNavigate();

  const goTop = () => {
    window.scrollTo({
      top: 0,
      
    });
    
  };

const [firstName, setFirstName] = useState('');
const [emailName, setemailName] = useState('');
const [courName, setcourlName] = useState('');
const [message, setmesgName] = useState('');
const [PHno, setPHno] = useState('');

 const submithandler =  (e) =>{
  e.preventDefault();
  
     addDoc(collection(db, "cont" ),{
      name: firstName,
      email: emailName,
      PHno : PHno,
      course: courName,
      messg: message,
  
    })

    navigate("/#Home");
    goTop();

 }


    return (
     
     <>
  
    
         <div><form className="flex flex-col pt-[30px] pr-[50px] pb-[50px] pl-[45px] bg-[#000000] relative md1000:w-[65%] md1000:flex md1000:flex-col md1000:mx-auto md1000:mt-14 min800:w-[90%] min620:w-full" 
    id ="cello" onSubmit={submithandler} 
   >
    
     <h3 className="text-[28px] font-bold mb-14 text-[#1e800b]">Leave Us Your Info</h3>
     <span className="bg-[#1e800b] w-[50px] h-[4px] absolute top-[77px]"></span>
     <input
       className="w-full py-[12px] px-[20px] h-[51px] text-[14px] border border-solid border-[#e4e4e4] outline-none mb-8"
       placeholder="Full Name *"
       type="text"
       id="name"
      value={firstName}
      onChange={e => setFirstName(e.target.value)}
   
      
     ></input>
      <input
       className="w-full py-[12px] px-[20px] h-[51px] text-[14px] border border-solid border-[#e4e4e4] outline-none mb-8"
       placeholder="Phone No. *"
       type="text"
       id="name"
      value={PHno}
      onChange={e => setPHno(e.target.value)}
   
      
     ></input>
     <input
       className="w-full py-[12px] px-[20px] h-[51px] text-[14px] border border-solid border-[#e4e4e4] outline-none mb-8"
       placeholder="Email Address *"
       type="email"
       id="email"
       value={emailName} 
      onChange={e => setemailName(e.target.value)}
 
    
     ></input>
     <select 
        className="w-full py-[12px] px-[20px] h-[51px] text-[14px] border border-solid border-[#e4e4e4] outline-none mb-8"
     id="wany" 
     value={courName} 
     onChange={e => setcourlName(e.target.value)}
  

      >
       <option>Select Class</option>
       <option>Body Building</option>
       <option>Functional Training</option>
       <option>Weight Loss</option>
       <option>Strengh Training</option>
       <option>Personal Training </option>
       <option>Workout</option>
       <option>Personalized Diet Plans </option>
       <option>Group Hit Classes</option>
       <option>Cycling</option>
       <option>other</option>
  
     </select>
     <textarea
       placeholder="Comment"
       className="w-full py-[12px] px-[20px] h-[140px] text-[14px] border border-solid border-[#e4e4e4] outline-none mb-8"
       id="message"
       value={message} 
       onChange={e => setmesgName(e.target.value)}
 
     
     ></textarea>
     <button
       type="submit"
       className="text-white bg-[#1e800b] w-fit py-[15px] px-[30px] font-bold text-[14px] uppercase self-center mt-6"
       onSubmit={submithandler}
       id='sub'
       
     >
       submit now
     </button>

   
</form></div>
</>
    );
  }

  
  export default Foo;
 