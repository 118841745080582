import Footer from "../components/Footer/Footer";
import GirlRunning from "../images/who-we-are/girl-run.png";
import GirlRedBg from "../images/who-we-are/girl-redbg.svg";
import GirlText from "../images/who-we-are/girl-side-text.png";
import GirlWind from "../images/who-we-are/wind.png";
import TitleRed from "../images/who-we-are/title-bg.svg";
import MainButton from "../components/MainButton";
import Img3 from "../images/AboutPage/3.jpg";
import Img4 from "../images/AboutPage/4.jpg";
import Img2 from "../images/AboutPage/2.jpg"
import Target from "../images/AboutPage/target.png";
import Mountain from "../images/AboutPage/mountain.png";

function About() {
  return (
    <>
      <section className="login-section">
        <div className="login-banner relative justify-center flex">
          <h1 className="text-white absolute bottom-[25px] text-[3rem] font-bold">
            About Us
          </h1>
        </div>
        <div className="container page-padding py-[5rem]">
          <div className="grid grid-cols-[1fr_1fr] h-[60rem] md1000:grid-cols-1">
            {/* title div -- */}
            <div className="flex flex-col !text-left relative items-right justify-center md1000:items-center md1000:!text-center">
              <p className="text-white relative  z-20 text-[15px] uppercase font-bold mb-8 ml-20 md1000:ml-3 ">
                who we are
              </p>
              <img
                src={TitleRed}
                alt="text_bg"
                className="w-[21rem] absolute top-[90px] min450:top-[25px] min375:top-[60px]"
              />

              <h2 className="text-[3.6rem] max-w-[53rem] font-bold mb-4 text-white">
                We Will Give You Strength and Health
              </h2>
              <p className="text-[#e3dabf] font-medium text-[15px] max-w-[50rem] ">
              Welcome to The Absylum, 
              Welcome to Fitness Oasis, where health transcends the ordinary. Every workout is a journey, and every member is family. Our gym is a judgment-free zone that welcomes all fitness levels, whether you're a seasoned athlete or just starting your fitness journey. At The Absylum, you'll find a supportive and empowering space dedicated to your growth and well-being.
              </p>
              <MainButton
                color={`!text-white`}
                bg={`bg-[#1e800b]`}
                goTo="/contact"
                text="contact us"
                arrowColor={`!text-white`}
                cN="pricing-cta blog-cta"
              />
            </div>
            {/*  */}
            <div className="relative w-[80%] -top-[90px] left-[40px] md1000:hidden">
              <img
                src={GirlRunning}
                alt="girl_running"
                className="girl-running"
              />
              <img src={GirlRedBg} alt="bg-red" className="girl-bg" />
              <img src={GirlText} alt="bg-text" className="girl-text" />
              <img src={GirlWind} alt="bg-wind" className="girl-wind" />
            </div>
          </div>
          {/*  */}
          <div className="mt-[10rem]">
            <div className="grid grid-cols-2 grid-rows-2 w-full min620:grid-cols-1">
              <div className="w-full bg-black h-[43rem] flex flex-col justify-center items-center text-center px-[100px] min800:px-[10px]">
                <img src={Target} alt="target_img" className="w-[9rem]" />
                <h2 className="text-[36px] font-bold mt-3 text-[#70f555]">Our Belief</h2>
                <p className="text-[16px] font-medium text-[#b8b7b3] mt-5">
                We at The Absylum believe in a 360-degree approach to health. Our team of certified trainers are not just here to instruct; they're here to inspire with personalized training programs tailored to your goals, ensuring every session is a step towards your unique fitness aspirations.

                </p>
              </div>
              {/*  */}
              <div className="w-full bg-white h-[43rem]">
                <img
                  src={Img3}
                  alt="about_img"
                  className="w-full h-full object-cover bg-center"
                />
              </div>
             
              <div className="w-full bg-black h-[43rem] flex flex-col justify-center items-center text-center px-[100px] min800:px-[10px]">
                <img src={Mountain} alt="target_img" className="w-[9rem]" />
                <h2 className="text-[36px] font-bold mt-3 text-[#6df752]">Our History</h2>
                <p className="text-[16px] font-medium text-[#c9b8b8] mt-5">
                We're dedicated to your fitness success! Our flexible membership plans adapt to your schedule, making it easy to achieve and surpass your goals. With a focus on sweat, dedication, and discipline, we celebrate milestones and support your transformation journey. Life is dynamic, and so are our plans, ensuring fitness seamlessly fits into your lifestyle.
                </p>
              </div>
               {/*  */}
               <div className="w-full bg-black h-[43rem]">
                <img
                  src={Img4}
                  alt="about_img"
                  className="w-full h-full object-cover bg-center"
                />
              </div>
              {/*  */}

              <div className="w-full bg-black h-[43rem] flex flex-col justify-center items-center text-center px-[100px] min800:px-[10px]">
                <img src={Mountain} alt="target_img" className="w-[9rem]" />
                <h2 className="text-[36px] font-bold mt-3 text-[#76ee5e]">Our Aim </h2>
                <p className="text-[16px] font-medium text-[#c9b8b8] mt-5">
                To unlock the door to a stronger you. Where sweat meets swagger, and every drop counts towards a fitter, happier you. We aim to make your body thank you and your future self, high-five you! To elevate every rep, to conquer every set; and take your body to THE NEXT LEVEL.
                </p>
              </div>
               {/*  */}
               <div className="w-full bg-black h-[43rem]">
                <img
                  src={Img2}
                  alt="about_img"
                  className="w-full h-full object-cover bg-center"
                />
              </div>
              {/*  */}
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default About;
