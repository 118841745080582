import Footer from "../components/Footer/Footer";
import Foo from "./form/form";
import PopupGfg from "./form/import";





function Login() {

 
  return (
    <>
      <section className="bg-black">
        <div className="login-banner relative justify-center flex">
          <h1 className="text-[#1e800b] absolute bottom-[25px] text-[3rem] font-bold">
            Contact Us
          </h1>
        </div>
        {/* contact */}
        <div className="bg- caret-red-200 py-[12rem] page-padding grid grid-cols-2 container gap-10 items-center md1000:grid-cols-1 ">
          {/* first col */}
          <div className="md1000:w-[60%] md1000:flex md1000:flex-col md1000:mx-auto min800:w-[90%] min620:w-full">
            <h2 className="text-[#1e800b] text-[36px] font-bold leading-[1.2]">
              We are here for help you! To Shape Your Body.
            </h2>
            <p className="text-[16px] w-[95%] text-[#ffffff] font-medium my-[40px]">
              At Next Level fitness, we are dedicated to helping you achieve the body of
              your dreams. Our expert trainers and nutritionists will work with
              you to create a personalized fitness and nutrition plan that helps
              you reach your specific goals.
            </p>
            <div className="grid grid-cols-2 grid-rows-2 gap-y-20 min620:grid-cols-1 min620:mx-auto">
              <div className="flex flex-col relative">
                <h3 className="text-[2rem] font-bold mb-10 text-[#1e800b]">
                 Bengaluru, Karnataka 
                </h3>
                <span className="bg-[#1e800b] w-[50px] h-[4px] absolute top-[33px]"></span>
                <p className="text-[15px] font-medium text-[#ffffff]">
                 <br />27/1 ,2nd Floor,
                <br /> Above Reliance Smart Point
                <br/> Horamavu Agara Main Rd,
                <br /> Chikka Pullappa Layout, Jayanti Nagar,
                </p>
              </div>
              {/*  */}
              <div className="flex flex-col relative">
                <h3 className="text-[2rem] font-bold mb-10 text-[#1e800b]">Opening Hours</h3>
                <span className="bg-[#1e800b] w-[50px] h-[4px] absolute top-[33px]"></span>
                <div className="text-[15px] font-medium text-[#ffffff] paddind 20px"  >

                  <p>Mon to Sta: 5:00 am — 10:00 pm</p>
                  <p>Sun : 6:00 am — 9:00 pm
                  </p>
                </div>
              </div>
              {/*  */}
              <div className="flex flex-col relative">
                <h3 className="text-[2rem] font-bold mb-10 text-[#1e800b]">Information</h3>
                <span className="bg-[#1e800b] w-[50px] h-[4px] absolute top-[33px]"></span>
                <div className="text-[15px] font-medium text-[#ffffff]">
                  <p> +91 - 8296014121
                  </p>
                  
                <a href="">theabsylum@gmail.com</a>
                </div>
              </div>
              {/*  */}
              <div className="flex flex-col relative">
                <h3 className="text-[2rem] font-bold mb-10 text-[#1e800b]">Follow Us On</h3>
                <span className="bg-[#1e800b] w-[50px] h-[4px] absolute top-[33px]"></span>
                <div className="text-[18px] font-medium text-white flex gap-5"> 
                 <a href="https://www.facebook.com/LimitlessFitnessTransformation"> <i className="fa-brands fa-facebook-f px-[16px] py-[13px] bg-[#1877F2]  rounded-full cursor-pointer hover:bg-[#1e800b] ease-in duration-200 hover:text-white"></i></a>
                  <i className="fa-brands fa-twitter p-[13.5px] bg-[#1DA1F2] rounded-full cursor-pointer hover:bg-[#1e800b] ease-in duration-200 hover:text-white"></i>
                 <a href="https://www.instagram.com/limitlessfitnesstransformation/"> <i className="fa-brands fa-instagram p-[13.5px] bg-[#ff0336]  rounded-full cursor-pointer hover:bg-[#1e800b] ease-in duration-200 hover:text-white"></i></a>
                  <i className="fa-brands fa-pinterest-p p-[13.5px] bg-[#d64848] rounded-full cursor-pointer hover:bg-[#1e800b] ease-in duration-200 hover:text-white"></i>
                </div>
              </div>
            </div>
          </div>
          
            
          
          {/* second col ---*/}
          <Foo />
          <PopupGfg />
  
        
        </div>
        
        {/* map */}
        /*<iframe
          
          src="https://www.google.com/maps/embed?pb=!https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.081041610541!2d77.65904193802984!3d13.03051104691354!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae177930242237%3A0xee21c2d4a39da3db!2sLimitless%20Fitness%20Transformation!5e0!3m2!1sen!2sin!4v1721506570426!5m2!1sen!2sin!1mhttps://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.081041610541!2d77.65904193802984!3d13.03051104691354!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae177930242237%3A0xee21c2d4a39da3db!2sLimitless%20Fitness%20Transformation!5e0!3m2!1sen!2sin!4v1721506570426!5m2!1sen!2sin8!1m3!1d3886.9685038637863!2d77.6485971!3d13.0376768!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae17472d94fd1b%3A0x181154c220fe169d!2sNext%20Level%20Fitness!5e0!3m2!1sen!2sin!4v1704538898405!5m2!1sen!2sin" 
          allowFullScreen=""
          loading="lazy"
          samesite="Strict"
          title="map"
          style={{ width: "100%", height: "45rem", border: "0px",background: "black" }}
        ></iframe>
        
        <Footer />
       
      </section>
    </>
  );
}

export default Login;
